import ExerciseType from "../api/enums/exerciseType";
import Exercise from "../api/interfaces/exercise";
import Workout from "../api/interfaces/workout";
import PreviewItem from "./previewItem";

interface SupersetOption {
    isSuperset: boolean;
    totalSetsLeftToPerform: number;
    currentSet: number;
}

class Preview {
    public exercises: Exercise[];
    public preview: PreviewItem[];
    private isInSuperset: boolean;
    private activeSupersetExercises: Exercise[];

    constructor(workout: Workout) {
        this.exercises = workout.data;
        this.preview = [];

        this.isInSuperset = false;
        this.activeSupersetExercises = [];
    }

    public sortExercises(): void {
        this.exercises.sort((a, b) => {
            return Number(a.sort_order) - Number(b.sort_order);
        });
    }

    public createPreview(): PreviewItem[] {
        this.exercises.forEach((exercise, index) => {
            const type = exercise.type;

            //Check if it's a superset
            if (exercise.superset_circuit) {
                if (type === ExerciseType.AMRAP) {
                    //if it's the first item in the superset, add start marker
                    if (!this.isInSuperset) {
                        this.createAMRAPStart(exercise);
                    }
                }

                //turn on superset mode
                this.isInSuperset = true;

                //Add one set
                this.performActionOnType(exercise, {
                    isSuperset: true,
                    totalSetsLeftToPerform: exercise.sets ?? 0,
                    currentSet: 1,
                });

                //Add to active superset
                this.activeSupersetExercises.push(exercise);
            } else {
                //Display what it needs to do, depending on type
                this.performActionOnType(exercise);
            }

            if (this.isInSuperset) {
                //check to see if the superset is ending
                if (this.isSupersetCircuitOver(index + 1)) {
                    if (type === ExerciseType.AMRAP) {
                        //add the ending marker
                        this.createAMRAPEnd(
                            this.activeSupersetExercises[0],
                            this.activeSupersetExercises[
                                this.activeSupersetExercises.length - 1
                            ]
                        );
                    } else {
                        //if the circuit is done, add the leftover sets of exercises
                        this.completeSuperset();
                    }

                    //reset flags
                    this.isInSuperset = false;
                    this.activeSupersetExercises = [];
                }
            }
        });

        return this.preview;
    }

    private performActionOnType(
        exercise: Exercise,
        superset?: SupersetOption
    ): void {
        switch (exercise.type) {
            case ExerciseType.REGULAR:
                superset?.isSuperset
                    ? this.createOneRegularSet(
                          exercise,
                          superset.totalSetsLeftToPerform,
                          superset.currentSet
                      )
                    : this.createRegularEntry(exercise);
                break;
            case ExerciseType.AMRAP:
                superset?.isSuperset
                    ? this.createOneAMRAPSet(exercise)
                    : this.createAMRAPSequence(exercise);
                break;
            case ExerciseType.EMOM:
                superset?.isSuperset
                    ? this.createOneEMOMSet(
                          exercise,
                          superset.totalSetsLeftToPerform,
                          superset.currentSet
                      )
                    : this.createEMOMEntry(exercise);
                break;
            case ExerciseType.TABATA:
                superset?.isSuperset
                    ? this.createOneTABATASet(
                          exercise,
                          superset.totalSetsLeftToPerform,
                          superset.currentSet
                      )
                    : this.createTABATAEntry(exercise);
                break;
            case ExerciseType.PROGRESSIVE_OVERLOAD:
                superset?.isSuperset
                    ? this.createOneProgressiveOverloadSet(
                          exercise,
                          superset.totalSetsLeftToPerform,
                          superset.currentSet
                      )
                    : this.createProgresiveOverloadEntry(exercise);
                break;
            default:
                throw new Error(
                    `Encountered an exercise type that does not exist. Must be one of the following types - [${Object.values(
                        ExerciseType
                    ).map((item) => `${item}, `)}]`
                );
        }
    }

    private isSupersetCircuitOver(nextElement: number): boolean {
        return !this.exercises[nextElement]?.superset_circuit;
    }

    private completeSuperset() {
        const initialSetsLeftToDo =
            (this.activeSupersetExercises[0].sets ?? 0) - 1;
        let setsLeft = initialSetsLeftToDo;

        while (setsLeft > 0) {
            for (let i = 0; i < this.activeSupersetExercises.length; i++) {
                //If last set and last item
                if (
                    setsLeft === 1 &&
                    i === this.activeSupersetExercises.length - 1
                ) {
                    this.performActionOnType(this.activeSupersetExercises[i], {
                        isSuperset: true,
                        totalSetsLeftToPerform:
                            initialSetsLeftToDo - setsLeft + 2, //forcing the total sets to equal the sets left to use exercise rest for last superset item
                        currentSet: initialSetsLeftToDo - setsLeft + 2,
                    });
                } else {
                    this.performActionOnType(this.activeSupersetExercises[i], {
                        isSuperset: true,
                        totalSetsLeftToPerform: initialSetsLeftToDo + 2,
                        currentSet: initialSetsLeftToDo - setsLeft + 2,
                    });
                }
            }
            setsLeft--;
        }
    }

    private createRegularEntry(exercise: Exercise) {
        const initialSets = exercise.sets ?? 0;
        let setsLeft = initialSets ?? 0;

        //Create an exercise and rest entry for each set
        while (setsLeft > 0) {
            this.createOneRegularSet(
                exercise,
                initialSets,
                initialSets - setsLeft + 1
            );

            setsLeft--;
        }
    }

    private createOneRegularSet(
        exercise: Exercise,
        totalSets: number,
        currentSet: number
    ) {
        const exerciseObject = {} as PreviewItem;
        const restObject = {} as PreviewItem;

        //display title and reps
        exerciseObject.title = `${exercise.title} (Set ${currentSet})`;
        exerciseObject.detail = `${exercise.reps} reps`;
        restObject.title = "Rest";

        if (totalSets === currentSet) {
            //display exercise rest
            restObject.detail = `${exercise.rest ?? 0} seconds`;
        } else {
            //display set rest
            restObject.detail = `${exercise.set_rest ?? 0} seconds`;
        }

        this.preview.push(exerciseObject);
        if (!exercise.superset_circuit || totalSets === currentSet) {
            this.preview.push(restObject);
        }
    }

    private createOneAMRAPSet(exercise: Exercise) {
        const exerciseObject = {} as PreviewItem;
        const restObject = {} as PreviewItem;

        //display title and reps
        exerciseObject.title = `${exercise.title}`;
        exerciseObject.detail = `${exercise.reps ?? 0} reps`;
        restObject.title = "Rest";
        restObject.detail = `${exercise.set_rest ?? 0} seconds`;

        this.preview.push(exerciseObject);
        // this.preview.push(restObject);
    }

    private createAMRAPStart(exercise: Exercise) {
        const startObject = {} as PreviewItem;

        startObject.title = exercise.progressive_reps
            ? `Perform the following (progressive reps) for ${exercise.duration} seconds`
            : `Perform the following for ${exercise.duration} seconds`;
        startObject.isAMRAPMarker = true;

        this.preview.push(startObject);
    }

    private createAMRAPEnd(firstExercise: Exercise, lastExercise: Exercise) {
        const endObject = {} as PreviewItem;
        const restObject = {} as PreviewItem;

        //display title and reps
        endObject.title = `... after ${firstExercise?.duration ?? 0} seconds`;
        endObject.isAMRAPMarker = true;
        restObject.title = "Rest";
        restObject.detail = `${lastExercise?.rest ?? 0} seconds`;

        this.preview.push(endObject);
        this.preview.push(restObject);
    }

    private createAMRAPSequence(exercise: Exercise) {
        this.createAMRAPStart(exercise);
        this.createOneAMRAPSet(exercise);
        this.createAMRAPEnd(exercise, exercise);
    }

    private createOneEMOMSet(
        exercise: Exercise,
        totalSets: number,
        currentSet: number
    ) {
        const exerciseObject = {} as PreviewItem;
        const restObject = {} as PreviewItem;

        //display title and reps
        exerciseObject.title = `${exercise.title} (Set ${currentSet})`;
        exerciseObject.detail = `${exercise.duration} seconds (attempt ${exercise.reps})`;

        this.preview.push(exerciseObject);

        if (totalSets === currentSet) {
            //display exercise rest
            restObject.title = "Rest";
            restObject.detail = `${exercise.rest ?? 0} seconds`;

            this.preview.push(restObject);
        }
    }

    private createEMOMEntry(exercise: Exercise) {
        const initialSets = exercise.sets ?? 0;
        let setsLeft = initialSets ?? 0;

        //Create an exercise and rest entry for each set
        while (setsLeft > 0) {
            this.createOneEMOMSet(
                exercise,
                initialSets,
                initialSets - setsLeft + 1
            );

            setsLeft--;
        }
    }

    private createTABATAEntry(exercise: Exercise) {
        const initialSets = exercise.sets ?? 0;
        let setsLeft = initialSets;

        //Create an exercise and rest entry for each set
        while (setsLeft > 0) {
            this.createOneTABATASet(
                exercise,
                initialSets,
                initialSets - setsLeft + 1
            );

            setsLeft--;
        }
    }

    private createOneTABATASet(
        exercise: Exercise,
        totalSets: number,
        currentSet: number
    ) {
        const exerciseObject = {} as PreviewItem;
        const restObject = {} as PreviewItem;

        //display title and reps
        exerciseObject.title = `${exercise.title} (Set ${currentSet})`;
        exerciseObject.detail = `${exercise.duration} seconds`;
        restObject.title = "Rest";

        if (totalSets === currentSet) {
            //display exercise rest
            restObject.detail = `${exercise.rest ?? 0} seconds`;
        } else {
            //display set rest
            restObject.detail = `${exercise.set_rest ?? 0} seconds`;
        }

        this.preview.push(exerciseObject);

        const restDuration =
            totalSets === currentSet ? exercise?.rest : exercise?.set_rest;

        if (restDuration && restDuration > 0) {
            this.preview.push(restObject);
        }
    }
    private createProgresiveOverloadEntry(exercise: Exercise) {
        const initialSets = exercise.sets ?? 0;
        let setsLeft = initialSets ?? 0;

        //Create an exercise and rest entry for each set
        while (setsLeft > 0) {
            this.createOneProgressiveOverloadSet(
                exercise,
                initialSets,
                initialSets - setsLeft + 1
            );

            setsLeft--;
        }
    }

    private createOneProgressiveOverloadSet(
        exercise: Exercise,
        totalSets: number,
        currentSet: number
    ) {
        const exerciseObject = {} as PreviewItem;
        const restObject = {} as PreviewItem;

        const reps =
            exercise?.progressive_overload_details &&
            Number(exercise?.progressive_overload_details[`Set ${currentSet}`]);

        if (!reps) return;

        //display title and reps
        exerciseObject.title = `${exercise.title} (Set ${currentSet})`;
        exerciseObject.detail = `${reps} reps`;
        restObject.title = "Rest";

        if (totalSets === currentSet) {
            //display exercise rest
            restObject.detail = `${exercise.rest ?? 0} seconds`;
        } else {
            //display set rest
            restObject.detail = `${exercise.set_rest ?? 0} seconds`;
        }

        this.preview.push(exerciseObject);
        let duration =
            totalSets === currentSet ? exercise.rest : exercise.set_rest;

        if (duration && duration > 0) {
            this.preview.push(restObject);
        }
    }
}

export default Preview;
