import ErrorResponse from "../interfaces/errorResponse";
import Workout from "../interfaces/workout";
import APIResult from "../types/apiResult";
import getResult from "../utils/getResult";

export const getWorkoutById = async (
    workout_id: number,
    token: string
): Promise<APIResult<Workout, ErrorResponse>> => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}workouts/${workout_id}/exercises`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    );

    const workout = (await response.json()) as APIResult<
        Workout,
        ErrorResponse
    >;

    return getResult<APIResult<Workout, ErrorResponse>>(response, workout);
};
