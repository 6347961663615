import React from "react";
import PreviewItem from "../preview/previewItem";
import "./styles/ExerciseItem.css";

type ExerciseItemProps = {
    item: PreviewItem;
};

const ExerciseItem = ({ item }: ExerciseItemProps) => {
    return (
        <div className={`card ${item.isAMRAPMarker && "amrap"}`}>
            <p>{item.title}</p>
            <p>{item.detail}</p>
        </div>
    );
};

export default ExerciseItem;
