/**
 * Transforms the response into usable properties.  Returning any becasue you can't return
 * @param response The response from the server.
 * @param data The json result from the server
 * @returns Object containing the usable error and result properties. Can't return type T in this function.
 */
const getResult = <T>(response: Response, data: T): any => {
    if (response.ok) return { result: data, error: undefined };
    return { result: undefined, error: data };
};

export default getResult;
