import { useEffect, useState } from "react";
import ErrorResponse from "../api/interfaces/errorResponse";
import Workout from "../api/interfaces/workout";
import { getWorkoutById } from "../api/requests/getWorkoutById";
import APIResult from "../api/types/apiResult";

const useWorkout = (workout_id: number, token: string) => {
    const [data, setData] = useState<Workout | undefined>(undefined);
    const [error, setError] = useState<ErrorResponse | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const result: APIResult<Workout, ErrorResponse> =
                await getWorkoutById(workout_id, token);

            if (result.error) {
                setData(undefined);
                setError(result.error);
            } else {
                setError(undefined);
                setData(result.result);
            }

            setIsLoading(false);
        })();
    }, [workout_id, token]);

    return { data, error, isLoading };
};

export default useWorkout;
