import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useWorkout from "../../hooks/useWorkout";
import Preview from "../../preview/preview";
import ExerciseItem from "../ExerciseItem";

const WorkoutPreview = () => {
    let { workoutId } = useParams();
    const [params] = useSearchParams();

    const { data, error, isLoading } = useWorkout(
        Number(workoutId),
        params.get("token") ?? ""
    );

    const renderExercises = () => {
        if (!data) return;

        const preview = new Preview(data);
        preview.sortExercises();
        const previewItems = preview.createPreview();

        return previewItems.map((item, index) => (
            <ExerciseItem key={index} item={item} />
        ));
    };

    if (isLoading) return <p>Loading...</p>;

    if (data) {
        return (
            <div>
                <h1 style={{ textAlign: "center" }}>
                    Mobile App Workout Sequence Preview (Workout {workoutId})
                </h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {renderExercises()}
                </div>
            </div>
        );
    }

    return <p>{error?.message}</p>;
};

export default WorkoutPreview;
