import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WorkoutPreview from "./components/pages/WorkoutPreview";
import NotFound from "./components/pages/NotFound";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="preview/:workoutId" element={<WorkoutPreview />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
